export interface ProductDetails {
  name: string
  details: {
    packagingSize: number
    form: string
    color: string
    price: string
  }
  imageUrl: string
  id: string
}

export const CoatingPowderDetails: Array<ProductDetails> = [
  {
    name: 'Colored Coating Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Purple',
      price: 'Rs 200/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/YF/XH/MY-6124050/powder-coating-products-500x500.jpg',
    id: 'coating',
  },
  {
    name: 'Traffic White PP Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'White',
      price: 'Rs 230/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/AK/DY/MY-6124050/traffic-white-pp-coating-powder-500x500.jpg',
    id: 'coating',
  },
  {
    name: 'Silver Glossy Coating',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Silver',
      price: 'Rs 240/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/LK/RM/MY-6124050/silver-glossy-coating-powder-500x500.jpg',
    id: 'coating',
  },
  {
    name: 'Black Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Black',
      price: 'Rs 180/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/DP/JA/MY-6124050/antique-black-coating-powder-500x500.jpg',
    id: 'coating',
  },
  {
    name: 'Rainbow Powder',
    details: {
      packagingSize: 10,
      form: 'Rainbow',
      color: 'White',
      price: 'Rs 180/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/VF/LD/MY-6124050/rainbow-coating-powder-500x500.jpg',
    id: 'coating',
  },
]
export const PolyesterPowderDetails: Array<ProductDetails> = [
  {
    name: 'RAL 9010 Epoxy Polyester',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'White',
      price: 'Rs 230/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/NC/DL/MY-6124050/ral-9010-epoxy-polyester-powder-coatings-500x500.jpg',
    id: 'polyester',
  },
  {
    name: 'RAL 9016 Epoxy Polyester',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Black',
      price: 'Rs 250/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/RI/JN/MY-6124050/ral-9016-epoxy-polyester-powder-coatings-500x500.jpg',
    id: 'polyester',
  },
  {
    name: 'RAL 2001 Epoxy Polyester',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Orange',
      price: 'Rs 200/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/BJ/UQ/MY-6124050/ral-2001-epoxy-polyester-coating-powder-500x500.jpg',
    id: 'hybrid',
  },
  {
    name: 'Orange Epoxy Polyester',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Orange',
      price: 'Rs 200/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/UH/CS/MY-6124050/light-orange-epoxy-coating-powder-500x500.jpg',
    id: 'hybrid',
  },
]
export const MetallicPowderDetails: Array<ProductDetails> = [
  {
    name: 'Metallic Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Purple',
      price: 'Rs 200/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/SL/HG/MY-6124050/metallic-coating-powder-500x500.jpg',
    id: 'metallic',
  },
  {
    name: 'Black Metallic Power',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Black',
      price: 'Rs 160/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/NG/HA/MY-6124050/metallic-black-coating-powder-500x500.jpg',
    id: 'metallic',
  },
]
export const StructurePowderDetails: Array<ProductDetails> = [
  {
    name: 'Structure Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Red',
      price: 'Rs 180/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/MR/AY/MY-6124050/structure-coating-powder-500x500.jpg',
    id: 'structure',
  },
]
export const HybridPowderDetails: Array<ProductDetails> = [
  {
    name: 'Hybrid Power',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'White',
      price: 'Rs 230/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/YA/BB/MY-6124050/hybrid-coating-powder-500x500.jpg',
    id: 'hybrid',
  },
]
export const GlassPowderDetails: Array<ProductDetails> = [
  {
    name: 'Glass Coating Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'White',
      price: 'Rs 600/L',
    },
    imageUrl:
      'https://5.imimg.com/data5/FH/BG/MY-6124050/glass-coating-powder-500x500.jpg',
    id: 'glass',
  },
]
export const AluminiumPowderDetails: Array<ProductDetails> = [
  {
    name: 'Aluminium Powder',
    details: {
      packagingSize: 10,
      form: 'Powder',
      color: 'Multiple Options',
      price: 'Rs 250/Kg',
    },
    imageUrl:
      'https://5.imimg.com/data5/OR/SU/MY-6124050/aluminium-coating-powder-500x500.jpg',
    id: 'aluminium',
  },
]
